import axios from 'axios';

import { storeToken } from './token.service';

class ApiService {
  static INSTANCE;
  static getInstance = () => {
    if (!ApiService.INSTANCE) ApiService.INSTANCE = new ApiService();
    return ApiService.INSTANCE;
  };

  constructor() {
    this.apiService = axios.create({
      baseURL: `${process.env.REST_API}/api/v1`,
      timeout: 18000,
    });
  }

  setAuthToken = (token, dontStoreToken) => {
    let token_ = null;
    if (token) {
      token_ = token.access_token;
    }
    this.apiService.defaults.headers.common = {
      ...this.apiService.defaults.headers.common,
      Authorization: `Bearer ${token_}`,
    };
    if (!dontStoreToken) storeToken(token);
  };
}

export default ApiService;
