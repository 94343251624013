import { connect } from 'react-redux';
import MainCourse from './Maincourse.component';

import {
  claimCertificate,
  createActivitySession,
  getActivitySession,
  getCertifConfiguration,
  getQuizSubmission,
  getStudentProgramActivityEntity,
  getStudentProgramActivityPerEntity,
  getStudentProgramDetails,
  getStudentQuizDetail,
  updateActivitySession,
  updateQuizSubmission,
  updateAssignmentSubmission,
  validateClaimCertif,
  getCourseDetail,
  getStudentLearningProgress,
} from '../../redux/actions/student.actions';

const mapStateToProps = (state) => ({
  name: state.header,
  common: state.common.settings,
  users: state.users,
  programSelection: state.student.programSelection,
  quizSelection: state.student.quizSelection,
  quizSubmissionHistory: state.student.quizSubmission,
  activitySessionUser: state.student.activitySession,
  activitySessionUserPrev: state.student.activitySessionPrevious,
  courseDetail: state.student.courseDetail.data,
  studentLearningProgress: state.student.studentLearningProgress,
});

const mapDispatchToProps = {
  getStudentProgramDetails,
  getStudentQuizDetail,
  getCourseDetail,
  getStudentLearningProgress,
  getCertifConfiguration,
  claimCertificate,
  validateClaimCertif,
  getActivitySession,
  createActivitySession,
  updateActivitySession,
  updateQuizSubmission,
  updateAssignmentSubmission,
  getStudentProgramActivityEntity,
  getStudentProgramActivityPerEntity,
  getQuizSubmission,
};

export default connect(mapStateToProps, mapDispatchToProps)(MainCourse);
