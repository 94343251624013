/* eslint-disable prettier/prettier */
/* eslint-disable indent */
import React, { useEffect, useRef, useState } from 'react';
import { get, random } from 'lodash';
import {
  Button,
  Card,
  Radio,
  Checkbox,
  Col,
  List,
  Row,
  Typography,
  Popover,
  Image,
  Popconfirm,
  Tooltip,
  Collapse,
  Input,
  Modal,
  Select,
  Tag,
  Form,
} from 'antd';
import {
  EditOutlined,
  ShareAltOutlined,
  LinkOutlined,
  LeftCircleOutlined,
  DeleteOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  FileSearchOutlined,
  SettingOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import parse from 'html-react-parser';
import {
  ReactPlayerCustom,
  ModalCreateEntity,
  MotionPage,
  VideoPlayerCustom,
  ModalPreviewEntitySession,
} from '../../../../components';
import { getParamURL } from '../../../../utils/parse';

import config from './DetailEntity.component.config';
import './DetailEntity.component.styles.css';
import YoutubePlayerCustom from '../../../../components/YoutubePlayerCustom/YoutubePlayerCustom.component';

const { Title, Text, Link } = Typography;
const { Panel } = Collapse;

const DetailEntity = ({
  selectedActivity,
  data,
  contentModerator,
  dataDetailQuiz,
  actionUpdateActivity,
  listQuiz,
  showQSR,
  sessionPreviewData,
  deleteActionEntity,
  actionDeleteActivityEntity,
  participantsData,
  fieldSuggestions,
  ...props
}) => {
  const [state, setState] = useState({
    editEntity: false,
    showModalEdit: false,
    showEntitySessionPreview: false,
    entitySelection: false,
  });

  const paramProgramID = getParamURL('id');
  const paramsActivityID = getParamURL('activity');
  const paramsEntityID = getParamURL('entity');

  // const deleteEntity = () => {
  //   if (deleteActionActivity) {
  //     deleteActionActivity(data);
  //   } else {
  //     console.error("deleteActionActivity prop is not provided");
  //   }
  // };

  useEffect(() => {
    console.log('deleteActionEntity:', deleteActionEntity);
  }, [deleteActionEntity]);


  const navigationLocalEntity = ({ ...setup }) => {
    setState((prevState) => {
      const currentState = { ...prevState };

      switch (setup.action) {
        case 'editEntity':
          currentState.editEntity = !currentState.editEntity;
          currentState.showModalEdit = !currentState.showModalEdit;
          break;
        default:
          currentState.editEntity = false;
          currentState.showModalEdit = false;
          break;
      }
      return { ...currentState };
    });
  };
  const actionUpdateEntity = (requestBody) => {
    actionUpdateActivity(requestBody);
    navigationLocalEntity({ action: 'editEntity' });
    setTimeout(() => {
      props.actionBack({ action: 'back' });
    }, 1000);
  };
  const generateVideoConfrence = () => (
    <>
      <Link href={get(data, 'material_link')} target="_blank">
        {get(data, 'material_link')}
      </Link>
      <Button type="link">
        Copy link <LinkOutlined />
      </Button>
    </>
  );
  const generateVideoLearning = () => (
    <Link disabled href={get(data, 'material_link')} target="_blank">
      {get(data, 'material_link')}
    </Link>
  );

  const generateContentVideo = () => {
    const materialSource = get(data, 'material_source');
    let content = null;
    if (
      materialSource === null ||
      materialSource === 0 ||
      materialSource === 1
    ) {
      content = <ReactPlayerCustom material={get(data, 'material_link')} />;
    } else if (materialSource === 2) {
      content = <VideoPlayerCustom material={get(data, 'material_link')} />;
    } else if (materialSource === 3) {
      // content = <YoutubePlayerCustom material={get(data, 'material_link')} />;
      // content = <YoutubePlayerCustom material={get(data, 'material_link')} />;
      content = <YoutubePlayerCustom
          material={data.material_link}
          selectionContent={data}
          // activitySessionUser={activitySessionUser}
          // onCreateActivitySession={onCreateActivitySession}
          // onUpdateActivitySession={onUpdateActivitySession}
          // onUpdateStateSession={onUpdateStateSession}
        />
    }
    return content;
  };

  const generateDocument = () => (
    <Link href={get(data, 'material_link')} target="_blank">
      {get(data, 'material_link')}
    </Link>
  );

  const generateItemsQuestion = (question) => {
    if (question) {
      return question.map((itemQuestion, indexQuestion) => {
        let imageQuestion = null;
        if (
          itemQuestion.images !== '' &&
          itemQuestion.images !== null &&
          itemQuestion.images !== undefined
        ) {
          imageQuestion = (
            <Row gutter={24} justify="start" className="row-image-question">
              {itemQuestion.images.split(', ').map((img) => (
                <Col>
                  <Image
                    className="question-image"
                    preview={{
                      src: img,
                    }}
                    style={{
                      backgroundImage: `url(${img})`,
                    }}
                  />
                </Col>
              ))}
            </Row>
          );
        }

        return (
          <div className="items-question">
            <div className="is-question">
              <Row gutter={24}>
                <Col md={20}>
                  {imageQuestion}
                  <Text>
                    {`${indexQuestion + 1}. `}
                    {itemQuestion?.question}
                  </Text>
                </Col>
                {/* <Col md={4}>
                  <Text>
                    <b>Score Weight: </b>
                    {itemQuestion?.score_weight}
                  </Text>
                </Col> */}
              </Row>
            </div>
            <div className="is-answer">
              {itemQuestion?.list_answer.map((itemAnswer, indexAnswer) => (
                <div>
                  {itemQuestion?.quiz_type === 1 && ( // single answer
                    <Radio
                      value={indexAnswer}
                      checked={get(itemAnswer, 'is_correct')}
                      onClick={() => console.log(indexAnswer, indexQuestion)}
                    >
                      <Text>
                        {`${config.defaultProps.alphabet[indexAnswer]}. ${itemAnswer?.answer} `}
                        {itemAnswer?.is_correct === 1 && <b>(correct)</b>}
                      </Text>
                    </Radio>
                  )}
                  {itemQuestion?.quiz_type === 2 && ( // multiple answer
                    <Checkbox
                      value={indexAnswer}
                      checked={get(itemAnswer, 'is_correct')}
                      onClick={() => console.log(indexAnswer, indexQuestion)}
                    >
                      <Text>
                        {`${config.defaultProps.alphabet[indexAnswer]}. ${itemAnswer?.answer} `}
                        {itemAnswer?.is_correct === 1 && <b>(correct)</b>}
                      </Text>
                    </Checkbox>
                  )}
                </div>
              ))}
            </div>
          </div>
        );
      });
    }
    return null;
  };

  const generateQuizDetail = () => {
    const parrentQuiz = dataDetailQuiz?.parrent;
    const itemsQuiz = dataDetailQuiz?.parrent?.items;
    return (
      <div className="quiz-content">
        <div className="quiz-info">
          <div className="quiz-header">
          <Title level={3}>{parrentQuiz?.quiz_name}</Title>
          <Button onClick={() => showQSR(data)}>Quiz Submission Result</Button>
          </div>
          <Row gutter={24}>
            <Col md={4}>
              <div>
                <Text>
                  <b>Min. Score :</b> {parrentQuiz?.min_score}
                </Text>
              </div>
              <div>
                <Text>
                  <b>Duration :</b> {parrentQuiz?.duration} Minutes
                </Text>
              </div>
              <div>
                <Text>
                  <b>Take Limit :</b> {parrentQuiz?.retake_limit}
                </Text>
              </div>
            </Col>
            <Col md={20}>
              <Text>
                <b>Description: </b>
                <br />
                {parrentQuiz?.description}
              </Text>
            </Col>
          </Row>
        </div>
        <div className="quiz-detail">
          <Card>{generateItemsQuestion(itemsQuiz)}</Card>
        </div>
      </div>
    );
  };

  const deleteEntityData = (entityData) => (
    <Popconfirm
      title={
        <p>
          Are you sure deleted this activity? <br />
          if you delete an activity, <br />
          the related entity will be deleted too
        </p>
      }
      onConfirm={() => {
        console.log('melakukan delete:', actionDeleteActivityEntity(entityData.id))
        // actionDeleteActivityEntity(entityData);
        props.actionBack({ action: 'back' });
      }}
      placement="left"
    >
      <Tooltip
        title="Remove this Activity"
        placement="top"
        className="button-delete"
      >
        <Button
          size="large"
          shape="circle"
          icon={<DeleteOutlined />}
          style={{ marginLeft: 10 }}
        />
      </Tooltip>
    </Popconfirm>
  );

  const [itemListWeekly, setItemListWeekly] = React.useState([]);
  const renderListParticipantWeeklyAssignment = (entityData) => {
    if (itemListWeekly.length === 0)
      props.participantWeeklyAssignment({
        programID: paramProgramID,
        entityID: entityData?.id
      }).then((resp) => setItemListWeekly(resp));

    return <div>
      <Title level={5}>List Learner and Submission</Title>
      <List
        itemLayout="horizontal"
        dataSource={itemListWeekly}
        renderItem={(item) => (
          <List.Item
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderBottom: '1px solid #888888',
              padding: '10px 20px',
            }}
          >
            <div style={{ fontSize: 12 }}>{item?.user_name}</div>
            <div
            >
              {item?.asssigment_status === 0
                ? (
                  <div style={{ display: 'flex' }}>
                    <div
                      style={{
                        height: 25,
                        width: 25,
                        borderRadius: 20,
                        padding: '1px 6px',
                        marginRight: 10,
                        background: '#B7B7B7',
                        color: 'white',
                      }}
                    >
                      <FileSearchOutlined />
                    </div>
                    <div
                      style={{
                        border: `1px solid #C11D1D`,
                        borderRadius: 10,
                        padding: '4px 8px',
                        color: `#C11D1D`,
                        fontSize: 10,
                      }}
                    >
                      Weekly assignment has not yet submitted
                      <CloseCircleFilled
                        style={{ marginLeft: '5px', fill: '#389517' }}
                      />
                    </div>
                  </div>
                )
                : (
                  <div style={{ display: 'flex' }}>
                    <div
                      style={{
                        height: 25,
                        width: 25,
                        borderRadius: 20,
                        padding: '1px 6px',
                        marginRight: 10,
                        background: '#C9264B',
                        color: 'white',
                      }}
                    >
                      <FileSearchOutlined />
                    </div>
                    <div
                      style={{
                        border: `1px solid #389517`,
                        borderRadius: 10,
                        padding: '4px 8px',
                        color: `#389517`,
                        fontSize: 10,
                      }}
                    >
                      Weekly assignment submitted
                      <CheckCircleFilled
                        style={{ marginLeft: '5px', fill: '#389517' }}
                      />
                    </div>
                  </div>
                )}
            </div>
          </List.Item>
        )}
        style={{ border: '1px solid #888888'}}
      />
    </div>;
  };

  const [finalGroup, setFinalGroup] = useState({
    loadData: true,
    list: [],
    availableUser: [],
    inputGroupName: false,
    openModalPIC: false,
    openModalMember: false,
    currentGroupID: false,
    tempSelectedPIC: null,
    tempSelectedMember: null,
  });
  const formModalPIC = useRef();
  const formModalMember = useRef();
  const finalGroupLoadData = () => {
    // request final group data
    if (finalGroup.loadData)
      props.finalGroupGet({
        'program_id': paramProgramID,
        'activity_id': paramsActivityID,
        'entity_id': paramsEntityID,
      }).then((response) => {
        setFinalGroup({
          ...finalGroup,
          loadData: false,
          list: response,
        });
      });
  };
  const addNewGroup = () => {
    // finalGroupGet={props.finalGroupGet}
    // finalGroupCreate={props.finalGroupCreate}
    // finalGroupPut={props.finalGroupPut}
    setFinalGroup({...finalGroup, loadData: true});
    props.finalGroupCreate({
      program_id: paramProgramID,
      activity_id: paramsActivityID,
      entity_id: paramsEntityID,
    }).then(() => finalGroupLoadData());
  };
  const updateFinalGroupName = (idx, value) => {
    setFinalGroup({...finalGroup, loadData: true});
    const dataExisting = finalGroup.list.find((item) => item.group_id === idx);
    props.finalGroupPut({
      program_id: paramProgramID,
      activity_id: paramsActivityID,
      entity_id: paramsEntityID,
      group_id: idx,
      group_name: value,
      user_pic: dataExisting?.user_pic_id,
      user_group: dataExisting?.user_group?.map((item) => item?.user_id),
    }).then(() => {
      setFinalGroup((prev) => {
        const current = {...prev};
        const findData = current.list.find((item) => item.group_id === idx);
        findData.group_name = value;
        current.inputGroupName = false;
  
        return {...current};
      });
      finalGroupLoadData();
    });
  };
  const openModalFinalGroup = (type, groupID) => {
    const userList = participantsData?.data;
    const collectDataUser = [];
    finalGroup.list.forEach((item) => {
      if (item?.user_pic_id)
        collectDataUser.push(item?.user_pic_id);
      item?.user_group?.forEach((group) => {
        collectDataUser.push(group?.user_id);
      });
    });

    // filter user avalibility
    const newAvailableUser = userList?.filter((item) => collectDataUser.indexOf(item?.id) < 0);
    const findExistingData = finalGroup.list.find((item) => item?.group_id === groupID);
    setFinalGroup((prevFinalGroup) => {
      const currentFinalGroup = {...prevFinalGroup};
      currentFinalGroup.availableUser = newAvailableUser.map((item) => ({...item, 'value': item.id, 'label': item.name}));
      currentFinalGroup.currentGroupID = groupID;
      if (type === 'pic') {
        currentFinalGroup.openModalPIC = true;
        currentFinalGroup.availableUser.push({'label': findExistingData?.user_pic_name, 'value': findExistingData?.user_pic_id, 'hidden': true});
        setTimeout(() => {
          formModalPIC.current.setFieldsValue({
            selected_pic: findExistingData?.user_pic_id,
          });
        }, 500);
      } else {
        currentFinalGroup.openModalMember = true;
        setTimeout(() => {
          formModalMember.current.setFieldsValue({
            selected_member: findExistingData?.user_group?.map((item) => ({...item, 'value': item.user_id, 'label': item.user_name})),
          });
        }, 500);
      }
      return {...currentFinalGroup};
    });
  };
  const renderModalFinalGroupPIC = () => (
    <Modal
      id="ModalFinalGroup"
      open={finalGroup.openModalPIC}
      title={
        <div className='modal-title'>
          <Title level={4} style={{ marginBottom: 'unset' }}>Update Group PIC</Title>
          <Text style={{ fontSize: 12 }}>Type learner name, and select one of them to assign them to be PIC</Text>
        </div>
      }
      footer={false}
      onCancel={() => {
        formModalPIC.current.setFieldsValue({ selected_pic: null });
        setFinalGroup({
          ...finalGroup,
          openModalPIC: false,
          currentGroupID: null,
          tempSelectedPIC: null,
        });
      }}
    >
      <Form ref={formModalPIC}>
        <Form.Item name="selected_pic">
          <Select
            showSearch
            placeholder="Select PIC"
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            options={finalGroup.availableUser}
            onSelect={(userID) => setFinalGroup({...finalGroup, tempSelectedPIC: userID})}
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Form>
      <Button
        type="primary"
        onClick={() => {
          const dataPIC = participantsData?.data.find((item) => item.id === finalGroup.tempSelectedPIC);
          const dataExisting = finalGroup.list.find((item) => item.group_id === finalGroup.currentGroupID);

          // update PIC
          dataExisting.user_pic_id = dataPIC.id;
          dataExisting.user_pic_name = dataPIC.name;

          // function update PIC to Rest API
          props.finalGroupPut({
            program_id: paramProgramID,
            activity_id: paramsActivityID,
            entity_id: paramsEntityID,
            group_id: dataExisting.group_id,
            group_name: dataExisting.group_name,
            // input data
            user_pic: dataPIC.id,
            user_group: dataExisting?.user_group?.map((item) => item?.user_id),
          }).then(() => {
            finalGroupLoadData();
            formModalPIC.current.setFieldsValue({ selected_pic: null });
            setFinalGroup({
              ...finalGroup,
              openModalPIC: false,
              currentGroupID: null,
              tempSelectedPIC: null,
            });
          });
        }}
        style={{ marginTop: 10 }}
      >
        Save
      </Button>
    </Modal>
  );
  const tagMemberRender = (propsOption) => {
    const { label, value, closable, onClose } = propsOption;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={value}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        closeIcon={<CloseCircleFilled style={{ color: '#CD8787' }} />}
        style={{
          background: '#FDECEC',
          color: 'black',
          marginRight: 3,
        }}
      >
        {label}
      </Tag>
    );
  };
  console.log(finalGroup);
  const renderModalFinalGroupMember = () => (
    <Modal
      id="ModalFinalGroup"
      open={finalGroup.openModalMember}
      title={
        <div className='modal-title'>
          <Title level={4} style={{ marginBottom: 'unset' }}>Update Group PIC</Title>
          <Text style={{ fontSize: 12 }}>Type learner name, and select one of them to assign them to be PIC</Text>
        </div>
      }
      footer={false}
      onCancel={() => {
        formModalMember.current.setFieldsValue({
          selected_member: [],
        });
        setFinalGroup({
          ...finalGroup,
          openModalMember: false,
          currentGroupID: null,
          tempSelectedMember: null,
        });
      }}
    >
      <Form ref={formModalMember}>
        <Form.Item name="selected_member">
          <Select
            name
            showSearch
            mode="multiple"
            placeholder="Select Member"
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            options={finalGroup.availableUser}
            tagRender={tagMemberRender}
            onChange={(selectedMember) => {
              console.log(selectedMember);
              setFinalGroup({
                ...finalGroup,
                tempSelectedMember: selectedMember,
              });
            }}
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Form>
      <Button
        type="primary"
        onClick={() => {
          const dataExisting = finalGroup.list.find((item) => item.group_id === finalGroup.currentGroupID);
          const dataNewMember = finalGroup.tempSelectedMember?.map((userID) =>
            participantsData?.data?.find((item) => item.id === userID),
          );
          // update PIC
          dataExisting.user_group = dataNewMember?.map((item) => ({...item, 'user_name': item.name, 'user_id': item.id}));
          // function update member to Rest API
          props.finalGroupPut({
            program_id: paramProgramID,
            activity_id: paramsActivityID,
            entity_id: paramsEntityID,
            group_id: dataExisting.group_id,
            group_name: dataExisting.group_name,
            // input data
            user_pic: dataExisting.user_pic_id,
            user_group: dataNewMember?.map((item) => item?.id)
          }).then(() => {
            finalGroupLoadData();
            formModalMember.current.setFieldsValue({
              selected_member: [],
            });
            setFinalGroup({
              ...finalGroup,
              openModalMember: false,
              currentGroupID: null,
              tempSelectedMember: null,
            });
          });
        }}
        style={{ marginTop: 10 }}
      >
        Save
      </Button>
    </Modal>
  );
  const renderConfigurationFinalGroup = () => {
    finalGroupLoadData();
    return (
      <div>
        <div
          style={{
            marginBottom: 20,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid #efefef',
            padding: '10px 0',
          }}
        >
          <Title level={4}>Group List</Title>
          <Button
            type="primary"
            icon={<PlusCircleOutlined />}
            onClick={() => addNewGroup()}
          >
            Add New Group
          </Button>
        </div>

        {/* rendering final group data */}
        {finalGroup.loadData && <List loading />}
        {!finalGroup.loadData && finalGroup.list.length === 0 && (
          <div>No Data</div>
        )}
        {!finalGroup.loadData && finalGroup.list.length > 0 && (
          <Collapse
            expandIconPosition="end"
            className="finalGroupSection"
          >
            {finalGroup.list.map((item) => (
              <Panel
                header={
                  item?.group_id === finalGroup.currentGroupID && finalGroup.inputGroupName
                    ? <Tooltip title="please insert group name, and enter">
                        <Input
                          placeholder="group name is not set"
                          style={{ width: 200 }}
                          onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                              setFinalGroup({...finalGroup, currentGroupID: null, inputGroupName: false})
                              updateFinalGroupName(item?.group_id, e.target.value);
                            }
                          }}
                        />
                      </Tooltip>
                    : item?.group_name
                }
                key={`finaleGroup${item?.group_id}`}
                extra={[
                  <EditOutlined
                    onClick={() => setFinalGroup({...finalGroup, currentGroupID: item?.group_id, inputGroupName: !finalGroup.inputGroupName})}
                    style={{ fontSize: 16, marginRight: 10 }}
                  />,
                  <SettingOutlined disabled style={{ fontSize: 16 }} />
                ]}
              >
                <div className="section-data">
                  <div className="info">
                    <div className="title">PIC</div>
                    <div className="name">
                      {item?.user_pic_name}
                    </div>
                  </div>
                  <div className="action">
                    <Button
                      type="primary"
                      onClick={() =>
                        openModalFinalGroup(
                          'pic',
                          item?.group_id,
                        )
                      }
                    >
                      Update PIC
                    </Button>
                  </div>
                </div>
                <div className="section-data">
                  <div className="info">
                    <div className="title">Team Member</div>
                    <div className="member-name">
                      {item?.user_group?.length === 0 && <div>not set</div>}
                      {item?.user_group?.map((group) => (
                        <div>{group.user_name}</div>
                      ))}
                    </div>
                  </div>
                  <div className="action">
                    <Button
                      type="primary"
                      onClick={() =>
                        openModalFinalGroup(
                          'member',
                          item?.group_id,
                        )
                      }
                    >
                      Update Team Member
                    </Button>
                  </div>
                </div>
                <div className="section-data end">
                  <div className="info">
                    <div className="title">Assignment Status</div>
                  </div>
                  <div className="option-status">
                    <div className='icon-file'>
                      <FileSearchOutlined
                        style={{ marginLeft: '5px', fill: '#389517' }}
                      />
                    </div>
                    <div className={`status-file ${item?.assignment_status === 1 ? 'success' : 'failed'}`}>
                      Group Assignment
                      {item?.assignment_status === 1 ? 'Submitted' : 'Not Submitted'}
                      {item?.assignment_status === 1 ? (
                        <CheckCircleFilled
                          style={{ marginLeft: '5px', fill: '#389517' }}
                        />
                      ) : (
                        <CloseCircleFilled
                          style={{ marginLeft: '5px', fill: '#389517' }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Panel>
            ))}
          </Collapse>
        )}
      </div>
    );
  };

  return (
    <MotionPage motionType="fadeIn">
      <div id="DetailEntity">
        <div className="section-header">
          <div className="action-header">
            <Button
              size="large"
              icon={<LeftCircleOutlined />}
              onClick={() => props.actionBack({ action: 'back' })}
              style={{ paddingLeft: 0 }}
            >
              Back
            </Button>
            <div>
              <Button
                size="large"
                icon={<EditOutlined />}
                onClick={() => navigationLocalEntity({ action: 'editEntity' })}
                disabled={Number(selectedActivity.is_active) === 1}
              >
                Edit
              </Button>
              <Button size="large" icon={<ShareAltOutlined />}>
                Share
              </Button>
              {deleteEntityData(data)}
            </div>
          </div>
          <div className="information-header">
            <div className="content-header">
              <Title level={2}>{get(data, 'entity_name')}</Title>
              {/* <Text>{data?.description}</Text> */}
            </div>
            <Row gutter={24} className="material">
              <Col md={18}>
                <div className="material-value" style={{ marginBottom: 10 }}>
                  <Title level={5}>Description</Title>
                  {data?.description?.map((item, index) => (
                    <div>
                      <Text>
                        {index + 1}. {item.description} (<b>{item.duration}</b>)
                      </Text>
                    </div>
                  ))}
                </div>
                {config.defaultProps.entityDontHaveLink.indexOf(
                  get(data, 'activity_type_id'),
                ) < 0 && (
                  <div className="material-value">
                    <Title level={5}>Material</Title>
                    <Text
                      ellipsis={{
                        rows: 1,
                        expandable: true,
                      }}
                    >
                      {get(data, 'activity_type_id') === 1 &&
                        generateVideoConfrence()}

                      {get(data, 'activity_type_id') === 2 &&
                        generateVideoLearning()}
                    </Text>
                  </div>
                )}
              </Col>
              <Col md={6} lex="auto" className="action">
                <Title level={5}>Instructure</Title>
                <Popover
                  placement="bottomRight"
                  trigger="click"
                  content={contentModerator}
                >
                  <Button block>
                    <span>
                      Change: <b>not exist</b>
                    </span>
                  </Button>
                </Popover>
              </Col>
            </Row>
          </div>
        </div>
        <div className="content-detail">
          {/* content video learning */}
          {get(data, 'activity_type_id') === 2 && generateContentVideo()}
          {/* content rich text */}
          {config.defaultProps.entityRichText.indexOf(
            get(data, 'activity_type_id'),
          ) >= 0 && <div>{parse(get(data, 'material_link'))}</div>}
          {/* content rich text Extend */}
          {config.defaultProps.entityRichTextExtend.indexOf(
            get(data, 'activity_type_id'),
          ) >= 0 && (<div>
            <div>
              <Title level={4}>Assignment Detail</Title>
              {get(data, 'material_additional') !== null && parse(get(data, 'material_additional'))}
            </div>
            <div className="material-link-assignment">
              {get(data, 'material_link') !== null && parse(get(data, 'material_link'))}
            </div>
            {get(data, 'activity_type_id') === 6 && (
              <div style={{ marginTop: 20 }}>
                {renderListParticipantWeeklyAssignment(data)}
              </div>
            )}
            {get(data, 'activity_type_id') === 8 && (
              <div style={{ marginTop: 20 }}>
                {renderConfigurationFinalGroup(data)}
              </div>
            )}
          </div>)}
          {/* content quiz */}
          {config.defaultProps.entityForQuizID.indexOf(
            get(data, 'activity_type_id'),
          ) >= 0 && generateQuizDetail()}
        </div>
        <ModalCreateEntity
          title="Edit Lesson Entity"
          showModal={state.showModalEdit}
          closeModal={navigationLocalEntity}
          actionCloseModal="editEntity"
          isEditData={state.editEntity}
          actionUpdateActivity={actionUpdateEntity}
          entitySelection={data}
          listQuiz={listQuiz}
          includeSchedule
          fieldSuggestions={fieldSuggestions}
        />
        <ModalPreviewEntitySession
          title="Session Preview"
          entityName={state.entitySelection?.entity_name || '-'}
          showModal={state.showEntitySessionPreview}
          // onCancel={() =>
          //   setState((prevState) => {
          //     const currentState = { ...prevState };
          //     currentState.entitySelection = null;
          //     currentState.showEntitySessionPreview = false;

          //     return { ...currentState };
          //   })
          // }
          data={sessionPreviewData}
        />
        {renderModalFinalGroupPIC()}
        {renderModalFinalGroupMember()}
      </div>
    </MotionPage>
  );
};

DetailEntity.displayName = config.displayName;
DetailEntity.defaultProps = config.defaultProps;
DetailEntity.propTypes = config.propTypes;

export default DetailEntity;
