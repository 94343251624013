import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  CopyOutlined,
  PlusOutlined,
  SearchOutlined,
  FilterFilled,
  CaretDownFilled,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Input,
  message,
  Modal,
  notification,
  Popconfirm,
  Select,
  Typography,
  Tabs,
  Collapse,
  Switch,
  InputNumber,
} from 'antd';

import { IMG_EMPTY } from '../../../assets/svg';
import {
  BaseLayout,
  DataTable,
  EmptyContent,
  MotionPage,
} from '../../../components';
import ModalCreate from './ModalCreate/ModalCreate.component';

import config from './AdminPrograms.component.config';
import './AdminPrograms.component.styles.css';

const { Title, Text } = Typography;

const AdminPrograms = ({
  clearSearchText,
  programCreate,
  programDuplicate,
  programList,
  programListData,
  searchText,
  setSearchText,
  programDetail,
  setParrentProgram,
  programGetTypeSuggestions,
  fieldSuggestions,
  ...props
}) => {
  const [apiCallStatus, setApiCallStatus] = React.useState({
    programList: false,
  });
  const [modalCreateVisibility, setModalCreateVisibility] =
    React.useState(false);
  const [modalCreateQuiz, setModalCreateQuiz] = React.useState(false);
  const numOfPage = JSON.parse(localStorage.getItem('PROGRAMS_CURRENT_PAGE'));
  const location = useLocation();
  const currentMenu = location.pathname;
  const accesedMenu = localStorage.getItem('CURRENT_MENU');
  const [current, setCurrent] = React.useState(
    numOfPage !== undefined ? numOfPage : 1,
  );
  const navigate = useNavigate();
  const [currentFilter, setCurrentFilter] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentID, setCurrentID] = useState(0);
  const [currentProgramName, setCurrentProgramName] = useState('');
  const [programName, setProgramName] = useState('');

  const defaultMenuFilter = [
    { value: '', label: 'Show All' },
    { value: 'bootcamp', label: 'Bootcamp' },
    { value: 'video learning content', label: 'Video Learning Content' },
    { value: 'mini class', label: 'Mini Class' },
  ];

  const filteredProgramListByTags = programListData.data.filter(
    (item) =>
      item?.program_type_name?.toLowerCase() === currentFilter.toLowerCase(),
  );

  const refreshPaginationProgram = (payload) => {
    if (payload?.paramProgramName) setSearchText(payload?.paramProgramName);
    localStorage.setItem('PROGRAMS_CURRENT_PAGE', JSON.stringify(payload.page));
    setCurrent(payload.page);
    programList(payload).catch(() =>
      notification.error({
        message: `System Error`,
        description: `Failed to get program list. Please try again later.`,
      }),
    );
  };

  const paginationParams = {
    showSizeChanger: false,
    current: programListData.page,
    pageSize: programListData.pageSize,
    total: programListData.totalRow,
    onChange: (numPage) => {
      refreshPaginationProgram({
        page: numPage,
      });
    },
  };

  const setCurrentPage = (index) => {
    setCurrent(index);
  };

  React.useEffect(() => {
    localStorage.setItem('CURRENT_MENU', currentMenu);
    if (accesedMenu !== currentMenu)
      localStorage.setItem('PROGRAMS_CURRENT_PAGE', JSON.stringify(1));
    setCurrent(JSON.parse(localStorage.getItem('PROGRAMS_CURRENT_PAGE')));
  }, [currentMenu, accesedMenu]);

  // useEffect for programList
  React.useEffect(() => {
    if (apiCallStatus.programList === false) {
      programList({ page: current })
        .catch(() =>
          notification.error({
            message: `System Error`,
            description: `Failed to get program list. Please try again later.`,
          }),
        )
        .finally(() =>
          setApiCallStatus({ ...apiCallStatus, programList: true }),
        );
    }

    return () => {
      clearSearchText();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiCallStatus.programList]);

  // useEffect for programGetTypeSuggestions
  React.useEffect(() => {
    if (modalCreateVisibility === true) {
      programGetTypeSuggestions().catch(() =>
        notification.error({
          message: `System Error`,
          description: `Unable to get program type suggestions`,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalCreateVisibility]);

  const actionDetail = (id) => (
    <Popconfirm
      placement="left"
      title="Are you sure want to duplicate this program?"
      onConfirm={(e) => {
        // const dataArr =
        //   (Array.isArray(programListData?.data) && programListData?.data) || [];
        // const duplicatedProgram = dataArr.find((datum) => datum.id === idx);
        setCurrentID(id);
        setCurrentProgramName(getProgramName(programListData.data, id));
        setIsModalOpen(true);
        e.stopPropagation();
      }}
      onCancel={(e) => {
        // Close the Popconfirm
        e.stopPropagation();
      }}
      okText="Salin Program"
      cancelText="Batalkan"
    >
      <Button
        type="secondary"
        icon={<CopyOutlined />}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        Duplicate
      </Button>
    </Popconfirm>
  );
  const actionExtraItem = (record) => {
    // const dataArr =
    //   (Array.isArray(programListData?.data) && programListData?.data) || [];
    // const foundData = dataArr.find((datum) => datum.id === idx);
    programDetail(record.id).then((resp) => {
      setParrentProgram(resp).then(() =>
        navigate(`${window.location.pathname}/detail?id=${record.id}`),
      );
    });
  };

  // CRUD section
  const createProgram = (payload) => {
    programCreate(payload)
      .then(() => {
        setModalCreateVisibility(false);
        setApiCallStatus({ ...apiCallStatus, programList: false });
      })
      .catch((err) => console.log('programCreate', err));
  };

  const duplicateProgram = (programId) => {
    programDuplicate(programId)
      .then(() => {
        setApiCallStatus({ ...apiCallStatus, programList: false });
        setCurrent(Math.ceil(programListData.data.length / 12));
      })
      .catch((err) => console.log('programDuplicate', err));
  };

  const getProgramName = (program, idProgram) => {
    const findObject = program.find((item) => item.id === idProgram);
    const defaultProgramName = findObject.program_name;
    return defaultProgramName;
  };

  return (
    <MotionPage motionType="fadeIn">
      <BaseLayout
        title="Programs"
        action={[
          <div>
            <Input
              size="middle"
              placeholder="Search Programs"
              allowClear
              style={{
                width: 300,
              }}
              prefix={<SearchOutlined />}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
                refreshPaginationProgram({
                  page: 1,
                  paramProgramName: e.target.value,
                });
              }}
            />
            <Select
              defaultValue=""
              style={{ width: 160, marginLeft: 10 }}
              suffixIcon={<FilterFilled />}
              options={defaultMenuFilter}
              onChange={(value) => {
                setCurrentFilter(value);
              }}
            />
          </div>,
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setModalCreateVisibility(true)}
          >
            Create Programs
          </Button>,
        ]}
        collapsedMenu={props?.common?.collapsedMenu}
      >
        <div id="AdminPrograms">
          <Tabs type="card">
            <Tabs.TabPane tab="Program List" key={1}>
              <Card>
                {Array.isArray(programListData?.data) ? (
                  <DataTable
                    loading={programListData?.isLoading}
                    columns={config.defaultProps.headColumn}
                    dataSource={programListData?.data?.filter((item) =>
                      item?.program_name
                        ?.toLowerCase()
                        ?.includes(searchText?.toLowerCase()),
                    )}
                    haveAction={[actionDetail]}
                    clickOnRow={actionExtraItem}
                    withPagination={paginationParams}
                    currentFilter={currentFilter}
                    filteredDataByTag={filteredProgramListByTags?.filter(
                      (item) =>
                        item?.program_name
                          ?.toLowerCase()
                          ?.includes(searchText?.toLowerCase()),
                    )}
                    setCurrentPage={setCurrentPage}
                    currentPage={current}
                  />
                ) : (
                  <EmptyContent
                    title="Kamu Belum Membuat Program"
                    description="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eveniet et
                veritatis, iste rerum illum, quod accusamus perferendis reprehenderit
                delectus quia qui saepe neque quaerat, earum ipsum libero. Officiis,
                ad suscipit!"
                    onButtonPressed={() => setModalCreateVisibility(true)}
                    buttonText="Create Programs"
                  />
                )}
              </Card>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Program Score Configuration" key={2}>
              <Card>
                <Collapse accordion="true" expandIconPosition="right">
                  <Collapse.Panel header="VLC Score Configuration">
                    <div className="vlc-content">
                      <div className="collect-switch">
                        <span>
                          Collect Attendace Score from Video Watch Activity
                        </span>
                        <Switch value={null} onChange={() => {}} />
                      </div>
                      <div className="config-switch">
                        <span>
                          Collect Attendace Score from Video Watch Activity
                        </span>
                        <Switch value={null} onChange={() => {}} />
                      </div>
                      <span>Attendance Score and Quiz Score Ratio</span>
                      <p>
                        This config will only apply to VLC that has quiz entity
                        in it at least 1
                      </p>
                      <div className="score-ratio">
                        <span>Attendance Score Ratio</span>
                        <InputNumber
                          min={0}
                          max={50}
                          // formatter={(value) => `${value}%`}
                          // parser={(value) => value?.replace('%', '')}
                          // value={inputNumber1}
                          // onChange={setInputNumber1}
                        />
                      </div>
                      <div className="score-ratio">
                        <span>Quiz Score Ratio</span>
                        <InputNumber
                          min={0}
                          max={50}
                          // formatter={(value) => `${value}%`}
                          // parser={(value) => value?.replace('%', '')}
                          // value={inputNumber2}
                          // onChange={setInputNumber2}
                        />
                      </div>
                      <p>Ratio should sum to exact 100% no more no less</p>
                      <Button type="primary" onClick={() => {}}>
                        Save
                      </Button>
                    </div>
                  </Collapse.Panel>
                  <Collapse.Panel header="Bootcamp Score Configuration">
                    <div className="bootcamp-content">
                      <div className="collect-switch">
                        <span>Collect Module Post Quiz Score</span>
                        <Switch />
                      </div>
                      <div className="config-switch">
                        <span>Collect Assignment / Project Score</span>
                        <Switch />
                      </div>
                      <div className="config-switch">
                        <span>Collect Group Project Score</span>
                        <Switch />
                      </div>
                      <span>
                        Group Project and Assignment score only will be
                        calculated when there is entity set inside the Program
                      </span>
                      <div className="score-ratio">
                        <span>Final Project Ratio</span>
                        <InputNumber
                          min={0}
                          max={50}
                          // formatter={(value) => `${value}%`}
                          // parser={(value) => value?.replace('%', '')}
                          // value={inputNumber3}
                          // onChange={setInputNumber3}
                        />
                      </div>
                      <p>
                        Final Module/Weekly ratio will be collected
                        automatically in relation to final project ratio above
                      </p>
                      <Button type="primary" onClick={() => {}}>
                        Save
                      </Button>
                    </div>
                  </Collapse.Panel>
                </Collapse>
              </Card>
            </Tabs.TabPane>
          </Tabs>
          <ModalCreate
            showModal={modalCreateVisibility}
            closeModal={() => setModalCreateVisibility(false)}
            createProgram={createProgram}
            fieldSuggestions={fieldSuggestions}
            totalPrograms={programListData.data.length}
            setCurrent={setCurrent}
          />
          <Modal
            title={`Membuat Salinan Program: ${currentProgramName}`}
            open={isModalOpen}
            centered
            closable={false}
            okText="Salin"
            cancelText="Batal"
            onOk={() => {
              const idProgram = {
                id: currentID,
                program_name: programName || null,
              };
              duplicateProgram(idProgram);
              setIsModalOpen(false);
            }}
            onCancel={() => setIsModalOpen(false)}
          >
            Silahkan masukan nama program yang kamu mau. Jika dikosongkan akan
            menggunakan nama default:{' '}
            <span style={{ fontWeight: 600 }}>{currentProgramName} (copy)</span>
            <Input
              placeholder={`${currentProgramName} (copy)`}
              value={programName}
              style={{ marginTop: 20 }}
              onChange={(e) => setProgramName(e.target.value)}
            />
          </Modal>
        </div>
      </BaseLayout>
    </MotionPage>
  );
};

AdminPrograms.displayName = config.displayName;
AdminPrograms.defaultProps = config.defaultProps;
AdminPrograms.propTypes = config.propTypes;

export default AdminPrograms;
