import { ExclamationCircleOutlined, LeftOutlined } from '@ant-design/icons';
import {
  Breadcrumb,
  Button,
  Collapse,
  Image,
  Input,
  Layout,
  List,
  Modal,
  Popover,
  Typography,
} from 'antd';

import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate, Navigate } from 'react-router-dom';
import { LoadingEvent, MotionPage } from '../../components';
import { generateNotification } from '../../utils/general.utils';
import { getParamURL } from '../../utils/parse';
import MainContent from './MainContent/MainContent.component';
import MainControl from './MainControl/MainControl.component';

import useGeneratePDF from '../../hook/useGenerateCertificate';
import config from './Maincourse.component.config';
import './Maincourse.component.styles.css';
import { getToken } from '../../services/token.service';
import { ICON_BOLBEL, ICON_BOLBEL_FULL } from '../../assets/svg';

const { Panel } = Collapse;
const { Header, Footer, Sider, Content } = Layout;
const { Title, Text } = Typography;
const { confirm } = Modal;

const MainCourse = ({
  programSelection,
  quizSelection,
  activitySessionUser,
  activitySessionUserPrev,
  getStudentProgramDetails,
  getStudentProgramActivityEntity,
  getStudentProgramActivityPerEntity,
  getStudentQuizDetail,
  getActivitySession,
  getCertifConfiguration,
  claimCertificate,
  validateClaimCertif,
  createActivitySession,
  updateActivitySession,
  updateQuizSubmission,
  updateAssignmentSubmission,
  getQuizSubmission,
  quizSubmissionHistory,
  courseDetail,
  studentLearningProgress,
  getCourseDetail,
  getStudentLearningProgress,
  ...props
}) => {
  const courseName = courseDetail.program_name;
  const courseDescription = courseDetail.description;
  const navigate = useNavigate();
  const [certifName, setCertifName] = useState('');
  const [collapsed, setCollapsed] = useState(false);
  const { generateCertificate, setIsModalOpen, isModalOpen, loadingClaim } =
    useGeneratePDF(courseDetail, certifName);
  const rawUrl = window.location.href;

  const [stateContent, setStateContent] = useState({
    events: getParamURL('events'),
    courseID: getParamURL('course'),
    ParrentID: getParamURL('prr'),
    ActivityID: getParamURL('act'),
    EntityID: getParamURL('ent'),

    activeContent: {
      parrentContent: false,
      activityContent: false,
      entityContent: false,
    },
    onLoadData: false,
    isDataLoadedFirst: false,
    isDataLoaded: true,
    selectionContentType: {},
    selectionContent: false,
    selectionContentPrev: false,

    onMobileContent: false,

    isContentCreated: false,
    contentListData: [],
    contentVideoPlaying: false,
    contentQuizRunning: false,

    loadingIndicator: false,
  });
  const currentParrent = stateContent.ParrentID;
  const currentActivity =
    stateContent.ActivityID !== '' ? `-${stateContent.ActivityID}` : '';
  const currentActivityEntity =
    stateContent.EntityID !== '' ? `-${stateContent.EntityID}` : '';
  const [defaultCheckTree, setDefaultCheckTree] = useState([
    `${currentParrent}${currentActivity}`.trim(),
    `${currentParrent}${currentActivity}${currentActivityEntity}`.trim(),
  ]);

  const onUpdateStateSession = ({ ...params }) => {
    setStateContent((prevState) => {
      const currentState = { ...prevState };
      currentState.contentVideoPlaying =
        params?.video_playing !== undefined
          ? params.video_playing
          : currentState.contentVideoPlaying;
      currentState.contentQuizRunning =
        params?.quiz_running !== undefined
          ? params.quiz_running
          : currentState.contentQuizRunning;
      return { ...currentState };
    });
  };

  const onGetActivitySession = ({ ...params }) => {
    getActivitySession(params).then((resp) => {
      if (get(resp, 'id')) {
        let idSubmission = get(resp, 'id');
        const history = get(resp, 'history');
        if (history.length > 0) {
          idSubmission = history[0].id;
        }
        // get history quiz submission
        if (params?.activity_type_id === 3) getQuizSubmission(idSubmission);
      }
    });
  };

  const onCreateActivitySession = ({ ...params }) => {
    // const entityIDSelected = stateContent.selectionContent?.entityID;
    // console.log('Entity ID Selected from maincourse: ', params.entity_id);
    const bodyRequest = {
      entity_id: params.entity_id,
      vod_duration: params?.vod_duration,
    };
    createActivitySession(bodyRequest).then(() => {
      getActivitySession(stateContent.selectionContent);
    });
  };

  const onUpdateActivitySession = ({ ...params }) => {
    // const entityIDSelected = stateContent.selectionContent?.entityID;
    // console.log('activitySessionUser id from maincourse: ', params.sessionID);
    const bodyRequest = {
      id: params.sessionID ?? params.id,
      vod_current_time: params?.vod_current_time,
      status: params?.status,
    };

    updateActivitySession(bodyRequest).then(() => {
      getActivitySession(stateContent.selectionContent);
      getStudentLearningProgress(courseDetail.id);
    });
  };

  const onUpdateQuizSubmission = ({ ...params }) => {
    // const entityIDSelected = stateContent.selectionContent?.entityID;
    const bodyRequestSubmission = {
      activity_session_id: activitySessionUser?.id,
      list_submission: params?.asnwerSubmit,
    };
    updateQuizSubmission(bodyRequestSubmission).then(() => {
      const bodyRequestUpdate = {
        id: activitySessionUser?.id,
        vod_current_time: params?.vod_current_time,
        status: 1,
      };
      updateActivitySession(bodyRequestUpdate).then(() => {
        getActivitySession(stateContent.selectionContent).then((resp) => {
          if (get(resp, 'id')) {
            let idSubmission = get(resp, 'id');
            const history = get(resp, 'history');
            if (history.length > 0) {
              idSubmission = history[0].id;
            }
            // get history quiz submission
            getQuizSubmission(idSubmission);
          }
        });
        getStudentLearningProgress(courseDetail.id);
      });
    });
  };

  const onUpdateAssignmentSubmission = ({ ...params }) => {
    const bodyRequestSubmission = {
      id: activitySessionUser?.id,
      assignment_submit: params?.assignmentSubmit,
    };
    updateAssignmentSubmission(bodyRequestSubmission).then(() => {
      const bodyRequestUpdate = {
        id: activitySessionUser?.id,
        status: 1,
      };
      updateActivitySession(bodyRequestUpdate).then(() => {
        getActivitySession(stateContent.selectionContent);
        getStudentLearningProgress(courseDetail.id);
      });
    });
  };

  const loadDataParrent = (idxParrent) => {
    setStateContent((prevState) => {
      const currentState = { ...prevState };
      currentState.selectionContent = false;
      currentState.selectionContentPrev = false;
      return { ...currentState };
    });

    const parrentID =
      idxParrent !== undefined ? idxParrent : stateContent.ParrentID;
    const sourceData = stateContent.contentListData;
    const findParrent = sourceData.find((items) => items.id === `${parrentID}`);
    setStateContent((prevState) => {
      const currentState = { ...prevState };
      currentState.selectionContentType = 'parrent';
      currentState.selectionContent = findParrent;
      currentState.isDataLoaded = true;
      currentState.onMobileContent = true;
      return { ...currentState };
    });
  };
  const loadDataActivity = (idxParrent, idxActivity) => {
    setStateContent((prevState) => {
      const currentState = { ...prevState };
      currentState.selectionContent = false;
      currentState.selectionContentPrev = false;
      return { ...currentState };
    });

    const parrentID =
      idxParrent !== undefined ? idxParrent : stateContent.ParrentID;
    const activityID =
      idxActivity !== undefined ? idxActivity : stateContent.ActivityID;
    const sourceData = stateContent.contentListData;
    const findParrent = sourceData.find((items) => items.id === `${parrentID}`);
    const findActivity = findParrent.activity_list.find(
      (items) => items.id === `${parrentID}-${activityID}`,
    );
    setStateContent((prevState) => {
      const currentState = { ...prevState };
      currentState.selectionContentType = 'activity';
      currentState.selectionContent = findActivity;
      currentState.isDataLoaded = true;
      currentState.onMobileContent = true;
      currentState.activeContent.parrentContent = findParrent;
      currentState.activeContent.activityContent = findActivity;
      currentState.activeContent.entityContent = false;
      return { ...currentState };
    });
  };
  const loadDataActivityEntityPrev = async (activityData) => {
    onGetActivitySession({
      ...activityData,
      isForDependency: true,
    }); // get activity entitiy session
    setStateContent((prevState) => {
      const currentState = { ...prevState };
      currentState.selectionContentPrev = activityData;
      return { ...currentState };
    });
    if (
      config.defaultProps.quizType.indexOf(activityData?.activity_type_id) >= 0
    ) {
      // reading material quiz
      getStudentQuizDetail(activityData?.material_link).then((quizResp) => {
        setStateContent((prevState) => {
          const currentState = { ...prevState };
          const entityData = {
            quizData: quizResp,
            ...currentState.selectionContentPrev,
          };
          currentState.selectionContentPrev = entityData;
          return { ...currentState };
        });
      });
    }
  };
  const loadDataActivityEntity = async (idxParrent, idxActivity, idxEntity) => {
    setStateContent((prevState) => {
      const currentState = { ...prevState };
      currentState.selectionContent = false;
      currentState.selectionContentPrev = false;
      return { ...currentState };
    });

    const parrentID =
      idxParrent !== undefined ? idxParrent : stateContent.ParrentID;
    const activityID =
      idxActivity !== undefined ? idxActivity : stateContent.ActivityID;
    const entityID =
      idxEntity !== undefined ? idxEntity : stateContent.EntityID;
    // const sourceData = stateContent.contentListData;

    // const findParrent = sourceData.find((items) => items.id === `${parrentID}`);
    const findActivity = courseDetail?.activity_list.find(
      (items) => items.id === `${parrentID}-${activityID}`,
    );
    const findActivityEntity = findActivity?.entity_list.find(
      (items) => items.id === `${parrentID}-${activityID}-${entityID}`,
    );
    const findActivityEntityIndexs = findActivity?.entity_list.findIndex(
      (items) => items.id === `${parrentID}-${activityID}-${entityID}`,
    );
    // const findActivityEntityByPosition = findActivity?.entity_list.find(
    //   (items) => items.position === findActivityEntity.position - 1,
    // );
    // changes method find indexing of array data
    const findActivityEntityByPosition =
      findActivity?.entity_list[findActivityEntityIndexs - 1];

    if (findActivityEntity !== undefined) {
      // set session and object for previous entity for entity with dependency
      // onGetActivitySession(findActivityEntity); // get activity entitiy session
      if (
        findActivityEntity.dependency === 1 &&
        findActivityEntityByPosition !== undefined
      ) {
        loadDataActivityEntityPrev(findActivityEntityByPosition);
      } else {
        setStateContent((prevState) => {
          const currentState = { ...prevState };
          currentState.selectionContentPrev = false;
          return { ...currentState };
        });
      }

      onGetActivitySession(findActivityEntity); // get activity entitiy session
      const updateEntityData = {
        statusActive: findActivityEntity.status,
        ...findActivityEntity,
      };
      if (
        config.defaultProps.quizType.indexOf(
          findActivityEntity?.activity_type_id,
        ) >= 0
      ) {
        // reading material quiz
        await getStudentQuizDetail(findActivityEntity?.material_link).then(
          (quizResp) => {
            const entityData = {
              quizData: quizResp,
              ...updateEntityData,
            };
            setStateContent((prevState) => {
              const currentState = { ...prevState };
              currentState.selectionContentType = 'entity';
              currentState.selectionContent = entityData;
              // currentState.activeContent.parrentContent = findParrent;
              currentState.activeContent.activityContent = findActivity;
              currentState.activeContent.entityContent = updateEntityData;
              currentState.isDataLoaded = true;
              currentState.onMobileContent = true;
              return { ...currentState };
            });
          },
        );
      } else {
        // reading material non quiz
        setStateContent((prevState) => {
          const currentState = { ...prevState };
          currentState.selectionContentType = 'entity';
          currentState.selectionContent = updateEntityData;
          // currentState.activeContent.parrentContent = findParrent;
          currentState.activeContent.activityContent = findActivity;
          currentState.activeContent.entityContent = updateEntityData;
          currentState.isDataLoaded = true;
          currentState.onMobileContent = true;
          return { ...currentState };
        });
      }
      // await getStudentProgramActivityPerEntity(
      //   findActivityEntity?.entityID,
      // ).then(async (respEntity) => {

      // });
    }
  };

  const navigateCourse = (paramNav) => {
    setStateContent((prevState) => {
      const currentState = { ...prevState };
      currentState.isDataLoaded = false;
      stateContent.ParrentID = paramNav?.parrent;
      stateContent.ActivityID = paramNav?.activity;
      stateContent.EntityID = paramNav?.entity;
      return { ...currentState };
    });
    const parrentID = paramNav?.parrent;
    const activityID = paramNav?.activity;
    const entityID = paramNav?.entity;

    // console.log(entityID);

    setTimeout(() => {
      let paramURL = `?course=${stateContent.courseID}`;
      if (parrentID !== null && activityID !== null && entityID !== null) {
        // parrent > activity > entity
        paramURL += `&prr=${parrentID}&act=${activityID}&ent=${entityID}`;
        loadDataActivityEntity(parrentID, activityID, entityID);
      }
      // else if (parrentID !== null && activityID !== null) {
      //   // parrent > activity
      //   paramURL += `&prr=${parrentID}&act=${activityID}`;
      //   loadDataActivity(parrentID, activityID);
      // } else {
      //   // parrent
      //   paramURL += `&prr=${parrentID}`;
      //   loadDataParrent(parrentID);
      // }

      navigate(`${window.location.pathname}${paramURL}`);
      config.defaultProps.handleScrollContentOnReloadContent();
    }, 500);
  };

  const findDataFromEvent = () => {
    let findObject = null;
    stateContent.contentListData.forEach((itemContent) => {
      itemContent.activity_list.forEach((itemActivity) => {
        if (itemActivity.id.includes(`${stateContent.ActivityID}`)) {
          findObject = itemActivity;
        }
        // find content from entity
        if (stateContent.EntityID !== '') {
          itemActivity.entity_list.forEach((itemEntity) => {
            if (
              itemEntity.id.includes(
                `${stateContent.ActivityID}-${stateContent.EntityID}`,
              )
            ) {
              findObject = itemEntity;
            }
          });
        }
      });
    });
    if (findObject !== null) {
      const splitObject = findObject.id.split('-');
      const parrentID = Number(splitObject[0]);
      const activityID = Number(splitObject[1]);
      const entityID = Number(splitObject[2]);

      setStateContent((prevState) => {
        const currentState = { ...prevState };
        currentState.ParrentID = parrentID;
        currentState.ActivityID = activityID;
        currentState.EntityID = entityID;
        return { ...currentState };
      });

      if (splitObject.length === 3) {
        setDefaultCheckTree(`${findObject.id}`);
        loadDataActivityEntity(parrentID, activityID, entityID);
      } else if (splitObject.length === 2) {
        setDefaultCheckTree(`${findObject.id}`);
        loadDataActivity(parrentID, activityID);
      }
    }
  };
  const handleLoadDataOnRefresh = () => {
    if (stateContent.events === 'calendar') {
      // lupa untuk yang mana
      findDataFromEvent();
    } else if (
      stateContent.ParrentID !== '' &&
      stateContent.ActivityID !== '' &&
      stateContent.EntityID !== ''
    ) {
      setTimeout(() => {
        setDefaultCheckTree([
          `${stateContent.ParrentID}-${stateContent.ActivityID}`,
          `${stateContent.ParrentID}-${stateContent.ActivityID}-${stateContent.EntityID}`,
        ]);
        navigateCourse({
          parrent: stateContent.ParrentID,
          activity: stateContent.ActivityID,
          entity: stateContent.EntityID,
        });
      }, 500);
      // loadDataActivityEntity();
    } else if (courseDetail?.activity_list[0]?.is_active !== 0) {
      const EntityID = courseDetail?.activity_list[0]?.entity_list[0]?.id;
      setDefaultCheckTree([
        `${EntityID.split('-')[0]}-${EntityID.split('-')[1]}`,
        `${EntityID}`,
      ]);
      navigateCourse({
        parrent: EntityID.split('-')[0],
        activity: EntityID.split('-')[1],
        entity: EntityID.split('-')[2],
      });
    }
    //  else if (
    //   stateContent.ParrentID !== '' &&
    //   stateContent.ActivityID !== ''
    // ) {
    //   setDefaultCheckTree([
    //     `${stateContent.ParrentID}-${stateContent.ActivityID}}`,
    //   ]);
    //   loadDataActivity();
    // } else if (stateContent.ParrentID !== '') {
    //   setDefaultCheckTree([`${stateContent.ParrentID}}`]);
    //   loadDataParrent();
    // }

    setStateContent((prevState) => {
      const currentState = { ...prevState };
      currentState.isDataLoadedFirst = !currentState.isDataLoadedFirst;
      return { ...currentState };
    });
  };

  const handleRefreshContent = (isFromControl) => {
    const entityExist = programSelection.activityEntity;
    entityExist.forEach((items) => {
      getStudentProgramActivityEntity(items.activityID);
    });
    if (isFromControl) {
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  };

  const firstLoadData = async () => {
    setStateContent((prevState) => {
      const currentState = { ...prevState };
      currentState.loadingIndicator = !currentState.loadingIndicator;
      return { ...currentState };
    });

    getCourseDetail(courseDetail.id).then(() => {
      getStudentLearningProgress(courseDetail.id);
    });

    // if (!isMobile && courseDetail?.activity_list?.length > 0) {
    //   if (courseDetail?.activity_list[0]?.is_active !== 0) {
    //     const EntityID = courseDetail?.activity_list[0]?.entity_list[0]?.id;
    //     // setDefaultCheckTree(`${EntityID}`);
    //     // navigateCourse({
    //     //   parrent: EntityID.split('-')[0],
    //     //   activity: EntityID.split('-')[1],
    //     //   entity: EntityID.split('-')[2],
    //     // });
    //     // loadDataActivityEntity(EntityID[0], EntityID[1], EntityID[2]); // Index 0 is Parrent ID, 1 is Activity ID, 2 is Entity ID
    //   }
    // }
    // getStudentProgramDetails(programSelection.data).then((activityData) => {
    //   for (let index = 0; index < activityData.length; ) {
    //     getStudentProgramActivityEntity(activityData[index].id);
    //     if (index === activityData.length - 1) handleCreatedContent();
    //     index += 1;
    //   }

    //   setStateContent((prevState) => {
    //     const currentState = { ...prevState };
    //     currentState.loadingIndicator = !currentState.loadingIndicator;
    //     return { ...currentState };
    //   });
    // });

    setStateContent((prevState) => {
      const currentState = { ...prevState };
      currentState.onLoadData = !currentState.onLoadData;
      return { ...currentState };
    });
  };

  useEffect(() => {
    let contentCreated = false;
    if (!stateContent.onLoadData) {
      firstLoadData();
    }

    if (stateContent.onLoadData) {
      if (
        !stateContent.isDataLoadedFirst &&
        courseDetail.activity_list.length > 0
      ) {
        handleLoadDataOnRefresh();
        config.defaultProps.handleScrollContentOnReloadContent();
      }
    }

    return () => {
      contentCreated = true;
    };
  });

  // useEffect(() => {
  //   if (!isMobile && courseDetail?.activity_list.length > 0) {
  //     if (courseDetail?.activity_list[0]?.is_active !== 0) {
  //       const EntityID =
  //         courseDetail?.activity_list[0]?.entity_list[0]?.id.split('-');
  //       loadDataActivityEntity(EntityID[0], EntityID[1], EntityID[2]); // Index 0 is Parrent ID, 1 is Activity ID, 2 is Entity ID
  //     }
  //   }
  // });

  const confirmBackHeaderMobile = ({ ...option }) => {
    confirm({
      width: 300,
      title: 'PERHATIAN!',
      icon: <ExclamationCircleOutlined />,
      content: option?.description,
      okText: 'Tetap di sini',
      onOk: () => {},
      cancelText: 'Tinggalkan dulu',
      onCancel: () => {
        setDefaultCheckTree([]);
        onUpdateStateSession({
          video_playing: false,
          quiz_running: false,
        });
        setStateContent((prevState) => {
          const currentState = { ...prevState };
          currentState.onMobileContent = false;
          currentState.selectionContent = false;
          currentState.activeContent = {
            parrentContent: false,
            activityContent: false,
            entityContent: false,
          };
          return { ...currentState };
        });
      },
    });
  };
  const actionBackHeader = () => {
    if (isMobile) {
      if (
        stateContent.selectionContent?.activity_type_id === 2 &&
        stateContent.contentVideoPlaying
      ) {
        confirmBackHeaderMobile({
          description:
            'Saat ini video sedang berjalan, bila kamu menutup sesi ini, video akan dihentikan!',
          isContent: 'video', // video / quiz
        });
      } else if (
        config.defaultProps.quizType.indexOf(
          stateContent.selectionContent?.activity_type_id,
        ) >= 0 &&
        stateContent.contentQuizRunning
      ) {
        confirmBackHeaderMobile({
          description:
            'Saat ini quiz sedang berjalan, bila kamu menutup sesi ini, jawaban yang sudah anda jawab akan hilang, dan jika quiz memiliki waktu pengerjaan, waktu akan dilanjutkan meski anda menutup sesi ini!',
          isContent: 'quiz', // video / quiz
        });
      } else {
        const data = getKey();
        if (
          data.parrentKey === null &&
          data.activityKey === null &&
          data.entityKey === null
        ) {
          navigate('/student/dashboard');
        } else {
          navigate(
            `${window.location.pathname}?course=${stateContent.courseID}`,
          );
          setStateContent((prevState) => {
            const currentState = { ...prevState };
            currentState.onMobileContent = false;
            currentState.selectionContent = false;
            currentState.activeContent = {
              parrentContent: false,
              activityContent: false,
              entityContent: false,
            };
            return { ...currentState };
          });
        }
      }
    } else {
      navigate('/student/dashboard');
    }
  };

  const getKey = () => {
    const searchParams = new URLSearchParams(rawUrl.split('?')[1]);
    const parrentKey = searchParams.get('prr');
    const activityKey = searchParams.get('act');
    const entityKey = searchParams.get('ent');

    return { parrentKey, activityKey, entityKey };
  };

  return (
    <MotionPage motionType="fadeIn">
      <Layout id="MainCourse" className={`${isMobile && 'mobile-version'}`}>
        {/* header course detail */}
        <Header
          id="MainCourseHeader"
          className={`${isMobile && 'mobile-version'}`}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'center',
            }}
          >
            <Button
              size="large"
              className="button-back-navigation"
              icon={<LeftOutlined />}
              onClick={() => {
                actionBackHeader();
              }}
            />
            {!isMobile && (
              <ICON_BOLBEL_FULL
                onClick={() => navigate('/student/dashboard')}
                style={{ cursor: 'pointer' }}
              />
              // <Image
              //   src={isMobile ? <ICON_BOLBEL /> : <ICON_BOLBEL_FULL />}
              //   className="logo"
              //   preview={false}
              //   width={isMobile ? 30 : 120}
              //   onClick={() => navigate('/student/dashboard')}
              //   style={{ cursor: 'pointer' }}
              // />
            )}
          </div>
          <div>
            <Title level={4} style={{ margin: 'unset' }}>
              {courseName}
            </Title>
          </div>
        </Header>
        {/* sider menu navigation */}
        <Layout hasSider>
          {!isMobile && (
            <Sider id="MainCourseControl" width={350}>
              <MainControl
                defaultCheckTree={defaultCheckTree}
                setDefaultCheckTree={setDefaultCheckTree}
                sourceData={stateContent}
                studentLearningProgress={studentLearningProgress.data}
                collapsed={collapsed}
                courseDetail={courseDetail}
                setCollapsed={setCollapsed}
                courseName={courseName}
                courseDescription={courseDescription}
                navigateCourse={navigateCourse}
                activitySessionUser={activitySessionUser}
                showLoading={!stateContent.isDataLoaded}
                onUpdateActivitySession={onUpdateActivitySession}
                onCreateActivitySession={onCreateActivitySession}
                onUpdateStateSession={onUpdateStateSession}
                handleRefreshContent={handleRefreshContent}
                loadContentIndicator={stateContent.loadingIndicator}
              />
            </Sider>
          )}
          <Content
            id="MainCourseContent"
            style={{
              padding: isMobile ? '70px 10px' : '64px 0px 0px 350px',
            }}
            className={`${
              stateContent.selectionContent.activity_type_id === 2 &&
              'isVideoContent'
            }`}
          >
            {isMobile && !stateContent.onMobileContent && (
              <div className="mainControl-collapse">
                <MainControl
                  defaultCheckTree={defaultCheckTree}
                  setDefaultCheckTree={setDefaultCheckTree}
                  sourceData={stateContent}
                  studentLearningProgress={studentLearningProgress.data}
                  collapsed={collapsed}
                  courseDetail={courseDetail}
                  setCollapsed={setCollapsed}
                  courseName={courseName}
                  courseDescription={courseDescription}
                  navigateCourse={navigateCourse}
                  activitySessionUser={activitySessionUser}
                  showLoading={!stateContent.isDataLoaded}
                  onUpdateActivitySession={onUpdateActivitySession}
                  onCreateActivitySession={onCreateActivitySession}
                  onUpdateStateSession={onUpdateStateSession}
                  handleRefreshContent={handleRefreshContent}
                  loadContentIndicator={stateContent.loadingIndicator}
                />
              </div>
            )}

            {/* render content if content have selected */}
            {console.log(
              'stateContent.selectionContent',
              stateContent.selectionContent,
            )}
            {stateContent.selectionContent && (
              <div
                className="section-content"
                style={{
                  margin: isMobile ? 0 : 30,
                  marginTop: 30,
                  marginBottom: 100,
                  display:
                    stateContent.selectionContent &&
                    courseDetail?.activity_list[0]?.is_active !== 0 &&
                    'block',
                }}
              >
                <MainContent
                  openModal={setIsModalOpen}
                  programSelection={programSelection?.data}
                  sourceData={stateContent}
                  courseDetail={courseDetail}
                  treeData={stateContent.contentListData}
                  activitySessionUser={activitySessionUser}
                  activitySessionUserPrev={activitySessionUserPrev}
                  quizSubmissionHistory={quizSubmissionHistory}
                  onCreateActivitySession={onCreateActivitySession}
                  onUpdateActivitySession={onUpdateActivitySession}
                  onUpdateQuizSubmission={onUpdateQuizSubmission}
                  onUpdateStateSession={onUpdateStateSession}
                  onUpdateAssignmentSubmission={onUpdateAssignmentSubmission}
                  navigateCourse={navigateCourse}
                  setDefaultCheckTree={setDefaultCheckTree}
                  onValidateClaimingCertif={validateClaimCertif}
                />
              </div>
            )}
          </Content>
        </Layout>
      </Layout>

      {/* modal certification */}
      <Modal
        title="Claim Certificate"
        open={isModalOpen}
        onOk={() => {
          generateCertificate(courseDetail, certifName || props.users?.person);
        }}
        okText="Claim Certificate"
        onCancel={() => {
          setCertifName('');
          setIsModalOpen(false);
        }}
        confirmLoading={loadingClaim}
      >
        <span style={{ fontWeight: 'bold', color: 'red' }}>
          PERHATIAN! Kamu hanya dapat claim sertifikat satu kali.
        </span>{' '}
        Silahkan periksa kembali nama kamu di preview sertifikat dibawah. Kamu
        dapat mengubahnya jika belum benar. Jika sudah benar, Klik &quot;Claim
        Certificate&quot; untuk melanjutkan.
        <Input
          placeholder={`${props.users.person}`}
          value={certifName}
          style={{ marginTop: 20 }}
          onChange={(e) => setCertifName(e.target.value)}
        />
      </Modal>
      <LoadingEvent
        showLoading={!stateContent.isDataLoaded}
        textLoading="Mohon tunggu, sedang memuat konten belajar kamu."
      />
    </MotionPage>
  );
};

MainCourse.displayName = config.displayName;
MainCourse.defaultProps = config.defaultProps;
MainCourse.propTypes = config.propTypes;

export default MainCourse;
