import React, { useState } from 'react';
import {
  Button,
  Tooltip,
  Typography,
  Modal,
  Collapse,
  Form,
  Input,
  InputNumber,
  Row,
  Col,
} from 'antd';
import {
  DeleteOutlined,
  AuditOutlined,
  CaretDownFilled,
  ExclamationCircleFilled,
  CheckCircleFilled,
  CloseCircleFilled,
  SearchOutlined,
  PlusSquareOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { parseInt } from 'lodash';

import { IMG_EMPTY } from '../../../../assets/svg';
import { DataList, EmptyContent } from '../../../../components';

import config from './ListLearnerPrograms.component.config';
import './ListLearnerPrograms.component.styles.css';
import { getParamURL } from '../../../../utils/parse';

const { Title, Text, Link } = Typography;

const testDataStudent = {
  namaStudent: 'Billy Kurniawan',
  program: 'DMB Batch 15',
  preQuizScore: 80,
  take1: 55,
  take2: 75,
  take3: 70,
  take4: 70,
  postQuizScore: null,
  weeklyAssign: 70,
  finalScore: 75,
};

const ExpandIcon = ({ isActive }) => (
  <CaretDownFilled rotate={isActive ? 0 : -90} style={{ fontSize: '25px' }} />
);

ExpandIcon.propTypes = {
  isActive: PropTypes.bool.isRequired,
};

const ListLearnerPrograms = ({
  navigationLocal,
  onRemoveLearner,
  participantsGetStudentData,
  reportStudent,
  programActivityListData,
  programParrent,
  programDetail,
  filteredParticipantsData,
  localSearchText,
  setLocalSearchText,
  participantsGetStudentQuizData,
  ...props
}) => {
  const navigate = useNavigate();
  const paramProgramID = getParamURL('id');

  const [removeLearnerModal, setRemoveLearnerModal] = useState({
    isVisible: false,
    participantDetail: null,
  });

  const [selectedLearner, setSelectedLearner] = useState({
    learner: null,
    show: false,
  });

  const [modalPopup, setModalPopup] = React.useState({
    itemSelected: null,
    modalQuiz: false,
    quizDataPopup: null,
    quizScorePopUp: null,
    modalSubmitQuiz: false,
    modalAssignment: false,
    modalSubmitAssignment: false,
  });

  const renderRemoveLearnerModal = ({ isVisible, participantDetail }) => (
    <Modal
      open={isVisible}
      onOk={() => {
        onRemoveLearner(participantDetail);
        setRemoveLearnerModal({ isVisible: false, participantDetail: null });
      }}
      onCancel={() =>
        setRemoveLearnerModal({ isVisible: false, participantDetail: null })
      }
    >
      <p>Are you sure you want to remove this learner?</p>
      <ul>
        <li key="learner-name">
          Name: <em>{participantDetail?.name}</em>
        </li>
        <li key="learner-email">
          Email: <em>{participantDetail?.email}</em>
        </li>
      </ul>
    </Modal>
  );

  const emptyContent = () => (
    <div className="empty-content">
      <div className="img-empty">
        <IMG_EMPTY />
      </div>
      <div className="content-empty">
        <Title level={2}>Kamu Belum Memasukan Learners</Title>
        <Text>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eveniet et
          veritatis, iste rerum illum, quod accusamus perferendis reprehenderit
          delectus quia qui saepe neque quaerat, earum ipsum libero. Officiis,
          ad suscipit!
        </Text>
      </div>
      <Button
        type="primary"
        onClick={() => navigationLocal({ action: 'selectionLearner' })}
      >
        Add Learner
      </Button>
    </div>
  );

  const actionActivity = (idx) => (
    <Tooltip title="Remove this learner">
      <Button
        type="text"
        shape="circle"
        size="large"
        icon={<DeleteOutlined />}
        onClick={() =>
          setRemoveLearnerModal({
            isVisible: true,
            participantDetail:
              Array.isArray(props?.dataSource) &&
              props?.dataSource?.find((el) => el?.id === idx),
          })
        }
      />
    </Tooltip>
  );

  const detailLearner = (idx) => (
    <Tooltip title="Detail Learner">
      <Button
        type="text"
        shape="circle"
        size="large"
        icon={<AuditOutlined />}
        onClick={() => {
          const learner = props?.dataSource?.find((el) => el?.id === idx);
          const programId = programParrent?.id;
          if (programId) {
            participantsGetStudentData({
              programId,
              userId: learner.id,
            }).then(() => {
              setSelectedLearner({ show: true, learner });
            });
          } else {
            console.error('Program ID not available');
          }
        }}
      />
    </Tooltip>
  );

  const combinedActions = (idx) => [detailLearner(idx), actionActivity(idx)];

  const openModalQuizHistory = (item) => {
    if (item?.configuration?.rasio_essay !== null) {
      participantsGetStudentQuizData(item?.session?.id).then((data) => {
        setModalPopup({
          ...modalPopup,
          quizDataPopup: data,
          itemSelected: item,
          modalQuiz: true,
        });
      });
    } else {
      setModalPopup({
        ...modalPopup,
        itemSelected: item,
        modalQuiz: true,
      });
    }
  };

  const openModalSubmitQuiz = (item) => {
    participantsGetStudentQuizData(item?.session?.id).then((data) => {
      setModalPopup({
        ...modalPopup,
        itemSelected: item,
        quizDataPopup: data,
        modalSubmitQuiz: true,
      });
    });
  };

  const modalInfoQuizReport = () => (
    <Modal
      className="modal-quiz-info"
      open={modalPopup.modalQuiz}
      onCancel={() =>
        setModalPopup({
          ...modalPopup,
          itemSelected: null,
          modalQuiz: false,
        })
      }
      centered
      maskClosable={false}
      footer={false}
      width={600}
    >
      <Title level={3}>Quiz Submission History</Title>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ margin: 0, width: 110 }}>
          <b>Quiz Name</b>
        </div>
        <div>: {modalPopup.itemSelected?.entity_name}</div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ margin: 0, width: 110 }}>
          <b>Student Name</b>
        </div>
        <div>: {selectedLearner.learner?.name}</div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ margin: 0, width: 110 }}>
          <b>Take Limit</b>
        </div>
        <div>: {modalPopup.itemSelected?.configuration?.retake_limit}</div>
      </div>
      {/* take limit if exist */}
      {modalPopup.itemSelected?.configuration?.retake_limit > 0 && (
        <div>
          <Row gutter={16}>
            {modalPopup.itemSelected?.session?.history?.map((list, index) => (
              <Col span={6} style={{ textAlign: 'center' }}>
                <div>
                  <b>Take {index + 1}</b>
                </div>
                <div>{list?.quiz_score}</div>
              </Col>
            ))}
          </Row>
        </div>
      )}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '50%',
        }}
      >
        <div style={{ margin: 0, width: 110 }}>
          <b>Highest Score</b>
        </div>
        <div style={{ color: '#C9264B' }}>
          <b>: {modalPopup.itemSelected?.session?.quiz_score}</b>
        </div>
      </div>
      {modalPopup.itemSelected?.configuration?.rasio_essay !== null && (
        <>
          <div style={{ marginTop: 10 }}>
            <b>Essay Question</b>
            <div>
              {modalPopup.quizDataPopup?.length > 0 &&
                modalPopup.quizDataPopup[0].question}
            </div>
          </div>
          <div style={{ marginTop: 10 }}>
            <b>Essay Answer</b>
            <div>
              {modalPopup.quizDataPopup?.length > 0 &&
                modalPopup.quizDataPopup[0].quizanswer_essay}
            </div>
          </div>
        </>
      )}
    </Modal>
  );

  const modaSubmitQuizReport = () => (
    <Modal
      className="modal-quiz-submit"
      open={modalPopup.modalSubmitQuiz}
      onCancel={() =>
        setModalPopup({
          ...modalPopup,
          quizDataPopup: null,
          modalSubmitQuiz: false,
        })
      }
      centered
      maskClosable={false}
      footer={false}
      width={500}
    >
      <Title level={3}>Submit Essay Score</Title>
      <div style={{ marginTop: 10 }}>
        <b>Essay Question</b>
        <div>
          {modalPopup.quizDataPopup?.length > 0 &&
            modalPopup.quizDataPopup[0].question}
        </div>
      </div>
      <div style={{ marginTop: 10 }}>
        <b>Essay Answer</b>
        <div>
          {modalPopup.quizDataPopup?.length > 0 &&
            modalPopup.quizDataPopup[0].quizanswer_essay}
        </div>
      </div>
      <div
        style={{
          marginTop: 20,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <b>Input Essay Score</b>
        </div>
        <div>
          <Input
            type="number"
            defaultValue={
              modalPopup.quizScorePopUp != null
                ? modalPopup.quizScorePopUp?.score_admin
                : modalPopup.itemSelected?.session?.quiz_submit_adm
            }
            style={{
              width: 70,
              textAlign: 'right',
            }}
            onChange={(e) => {
              const valueQuiz = e.target.value;
              if (valueQuiz > 0) {
                setTimeout(() => {
                  props
                    .participantsGenerateStudentQuiz({
                      quiz_id: parseInt(modalPopup.itemSelected?.material_link),
                      session_id: modalPopup.itemSelected?.session?.id,
                      score: parseFloat(valueQuiz),
                    })
                    .then((resp) => {
                      setModalPopup({
                        ...modalPopup,
                        quizScorePopUp: resp,
                      });
                    });
                }, 1000);
              }
            }}
          />
        </div>
      </div>
      <div
        style={{
          marginTop: 10,
          marginRight: 25,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <b>Multiple Choice Score</b>
        </div>
        <div>
          <b>
            {modalPopup.quizScorePopUp != null
              ? modalPopup.quizScorePopUp?.score_student
              : modalPopup.itemSelected?.session?.quiz_submit_std}
          </b>
        </div>
      </div>
      <div
        style={{
          marginTop: 10,
          marginRight: 25,
          marginBottom: 20,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <b>Quiz Final Score</b>
        </div>
        <div>
          <b>
            {modalPopup.quizScorePopUp != null
              ? modalPopup.quizScorePopUp?.final_score
              : modalPopup.itemSelected?.session?.quiz_score}
          </b>
        </div>
      </div>
      <Button
        type="primary"
        onClick={() => {
          props
            .participantSubmitStudentQuiz({
              quiz_id: parseInt(modalPopup.itemSelected?.material_link),
              session_id: modalPopup.itemSelected?.session?.id,
              score: parseFloat(modalPopup.quizScorePopUp?.score_admin),
            })
            .then((resp) => {
              participantsGetStudentData({
                programId: programParrent?.id,
                userId: selectedLearner.learner.id,
              }).then(() => {
                setModalPopup({
                  ...modalPopup,
                  modalSubmitQuiz: false,
                });
              });
            });
        }}
      >
        Submit
      </Button>
    </Modal>
  );

  const openModalAssignment = (item) => {
    setModalPopup({
      ...modalPopup,
      itemSelected: item,
      modalAssignment: true,
    });
  };

  const [valueWeeklySubmission, setValueWeeklySubmission] = React.useState(0);
  const modalSubmitAssignmentReport = () => (
    <Modal
      className="modal-assignment"
      open={modalPopup.modalAssignment}
      onCancel={() =>
        setModalPopup({
          ...modalPopup,
          modalAssignment: false,
        })
      }
      centered
      maskClosable={false}
      footer={false}
      width={500}
    >
      <Title level={3}>Submit Assignment Score</Title>
      <div style={{ marginTop: 10 }}>
        <b>Learner’s assignment submission</b>
        <div>
          <Link
            href={modalPopup.itemSelected?.session?.assignment_submit?.url}
            target="_blank"
          >
            {modalPopup.itemSelected?.session?.assignment_submit?.name}
          </Link>
        </div>
      </div>
      <div
        style={{
          marginTop: 20,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <b>Input Assignment Score</b>
        </div>
        <div>
          <Input
            type="number"
            // defaultValue={
            //   modalPopup.quizScorePopUp != null
            //     ? modalPopup.quizScorePopUp?.score_admin
            //     : modalPopup.itemSelected?.session?.quiz_submit_adm
            // }
            style={{
              width: 70,
              textAlign: 'right',
            }}
            onChange={(e) => {
              const valueSubmit = e.target.value;
              setValueWeeklySubmission(valueSubmit);
            }}
          />
        </div>
      </div>
      <Button
        type="primary"
        onClick={() => {
          props
            .participantSubmitWeekAssignment({
              session_id: modalPopup.itemSelected?.session?.id,
              score: parseFloat(valueWeeklySubmission),
            })
            .then((resp) => {
              participantsGetStudentData({
                programId: programParrent?.id,
                userId: selectedLearner.learner.id,
              }).then(() => {
                setModalPopup({
                  ...modalPopup,
                  modalAssignment: false,
                });
              });
            });
        }}
      >
        Submit
      </Button>
    </Modal>
  );

  // const modalSubmitAssignmentGroupReport = () => (
  //   <Modal
  //     open={openModalAssignmentGroup}
  //     onOk={handleOkAssignmentGroup}
  //     confirmLoading={confirmLoading}
  //     onCancel={handleCancel}
  //     className="modal-assignment-group"
  //   >
  //     {/* assignment info */}
  //     {/* learner submission */}
  //     {/* list learner submit score */}
  //   </Modal>
  // );

  const collapseListDataReport = (idx, items) => {
    const isQuizEssay = (item) => {
      let stateItem = null;
      if (item.activity_type_id === 3 || item.activity_type_id === 7) {
        if (item.configuration.rasio_essay === null) {
          stateItem = (
            <Button type="default" className="disabled" disabled>
              Tidak Ada Essay
            </Button>
          );
        } else {
          stateItem = (
            <Button
              type="primary"
              className={item.session?.quiz_score ? 'success' : 'not'}
              onClick={() => {
                openModalSubmitQuiz(item);
              }}
            >
              {item.session?.quiz_score
                ? 'Essay Score Submited'
                : 'Submit Essay Score'}
              {item.session?.quiz_score ? (
                <CheckCircleFilled
                  style={{ marginLeft: '5px', fill: '#389517' }}
                />
              ) : (
                <CloseCircleFilled
                  style={{ marginLeft: '5px', fill: '#389517' }}
                />
              )}
            </Button>
          );
        }
      }
      return stateItem;
    };

    const isAssignment = (item) => {
      let stateItem = null;
      if (item.activity_type_id === 6 || item.activity_type_id === 8) {
        stateItem = (
          <Button
            type="primary"
            className={item.session?.assignment_submit ? 'success' : 'not'}
            onClick={() =>
              item.session?.assignment_submit && openModalAssignment(item)
            }
          >
            Assignment Score{' '}
            {item.session?.assignment_submit ? 'Submitted' : 'Not Submitted'}
            {item.session?.assignment_submit ? (
              <CheckCircleFilled
                style={{ marginLeft: '5px', fill: '#389517' }}
              />
            ) : (
              <CloseCircleFilled
                style={{ marginLeft: '5px', fill: '#389517' }}
              />
            )}
          </Button>
        );
      }
      return stateItem;
    };

    const renderScore = (item) => {
      let scoring = '--';
      if (item.activity_type_id === 3 || item.activity_type_id === 7) {
        if (item.session?.quiz_score !== null)
          scoring = item.session?.quiz_score;
      } else if (item.activity_type_id === 6 || item.activity_type_id === 8) {
        if (item.session?.assignment_score !== null)
          scoring = item.session?.assignment_score;
      }
      return scoring;
    };

    return (
      <>
        {items.map((item) => (
          <div key={item.id} className="list-item">
            <div className="list-item-contents">
              <div>{item.entity_name}</div>
              <div className="action-item-contents">
                <div className="list-item-score">
                  <div>{renderScore(item)}</div>
                  <ExclamationCircleFilled
                    onClick={() => {
                      if (
                        item.activity_type_id === 3 ||
                        item.activity_type_id === 7
                      ) {
                        openModalQuizHistory(item);
                      }
                      // openQuizHistoryModal(item.session, item.configuration);
                      // console.log('configuration :', item.configuration);
                    }}
                  />
                </div>
                <div className="list-item-button">
                  {isQuizEssay(item)}
                  {isAssignment(item)}
                </div>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  };

  const reportLearner = () => (
    <div id="DetailLearner" style={{ marginTop: 20 }}>
      <div style={{ display: 'flex' }}>
        <Title level={5} style={{ width: '130px', margin: 0 }}>
          Nama Student
        </Title>
        <Title level={5} style={{ margin: 0 }}>
          : {selectedLearner.learner.name}
        </Title>
      </div>
      <div style={{ display: 'flex' }}>
        <Title level={5} style={{ width: '130px', margin: 0 }}>
          Program
        </Title>
        <Title level={5} style={{ margin: 0 }}>
          : {programParrent.program_name}
        </Title>
      </div>
      <Title level={5} style={{ marginTop: '10px' }}>
        Rincian Program
      </Title>
      <Collapse
        accordion="true"
        expandIcon={ExpandIcon}
        expandIconPosition="end"
      >
        {reportStudent.data.activity_list.map((item) => (
          <Collapse.Panel
            header={<Title level={5}>{item.activity_name}</Title>}
          >
            {collapseListDataReport(item.id, item.entity_list)}
          </Collapse.Panel>
        ))}
      </Collapse>
      <div
        style={{
          display: 'flex',
          width: '100%',
          padding: '10px 20px',
          marginTop: '10px',
          justifyContent: 'space-between',
        }}
      >
        <Title level={5} style={{ width: '130px', margin: 0 }}>
          Final Score
        </Title>
        <div style={{ width: 350 }}>
          <Title level={5} style={{ margin: 0 }}>
            - comming soon
          </Title>
        </div>
      </div>
      <div
        className="button-action-report"
        style={{ marginTop: 20, marginBottom: 20 }}
      >
        <Button
          type="default"
          onClick={() => setSelectedLearner({ show: false })}
        >
          Back
        </Button>
      </div>
    </div>
  );

  return (
    <div>
      {props.showEmptyLearner && emptyContent()}
      {!selectedLearner.show && props.showListLearner && (
        <div className="content-detail">
          <div className="section-header">
            <div className="title">
              <Title level={4} style={{ margin: 0 }}>
                Learners
              </Title>
              <Text>{props?.dataSource?.length} Learners</Text>
            </div>
            <div>
              <Input
                prefix={<SearchOutlined />}
                style={{
                  width: 300,
                  display: 'flex',
                  gap: 2,
                }}
                allowClear
                placeholder="Find learner..."
                onChange={(e) =>
                  setLocalSearchText({
                    ...localSearchText,
                    learner: e.target.value,
                  })
                }
              />
            </div>
            <div>
              <Button
                size="middle"
                icon={<PlusSquareOutlined />}
                onClick={() => navigationLocal({ action: 'selectionLearner' })}
              >
                Add Learners
              </Button>
            </div>
          </div>
          <DataList
            withPagination={5}
            disablePagination
            data={(Array.isArray(props.dataSource) && props.dataSource) || []}
            haveAction
            actionDetail={combinedActions}
          />
        </div>
      )}
      {renderRemoveLearnerModal(removeLearnerModal)}
      {selectedLearner.show && reportLearner()}
      {modalInfoQuizReport()}
      {modaSubmitQuizReport()}
      {modalSubmitAssignmentReport()}
    </div>
  );
};

ListLearnerPrograms.displayName = config.displayName;
ListLearnerPrograms.defaultProps = config.defaultProps;
ListLearnerPrograms.propTypes = config.propTypes;

export default ListLearnerPrograms;
